import React, { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

function App() {
  const [age, setAge] = useState<number>();
  useEffect(() => {
    setAge(
      new Date(
        new Date().getTime() - new Date("2002/12/02").getTime()
      ).getFullYear() - 1970
    );
  }, []);
  return (
    <div className="App">
      <h1>Hi, I'm Daniel.</h1>
      <h4>CS student@KIT. I enjoy tinkering with code.</h4>
      <a href="https://github.com/danieldietzler">
        <FontAwesomeIcon icon={faGithub} size="2x" color="black" />
      </a>
    </div>
  );
}

export default App;
